import React from 'react'

export function useFetch({url, body}) {
  const [state, dispatch] = React.useReducer(fetchReducer, {
    fetching: false,
    data: null,
    error: null,
  })
  React.useEffect(() => {
    if (body) {
      dispatch({type: 'fetching'})
      fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body,
      })
        .then(r => (r.ok ? r : Promise.reject(r)))
        .then(
          async response => {
            const data = await response.json()
            dispatch({type: 'fetched', data})
          },
          async error => {
            try {
              dispatch({
                type: 'error',
                error:
                  (await error.json?.()) || (await error.text?.()) || error,
              })
            } catch (_ignore) {
              // we tried to parse the response... But no dice so...
              dispatch({type: 'error', error})
            }
          },
        )
    }
  }, [url, body])
  return state
}

function fetchReducer(state, {type, data, error}) {
  switch (type) {
    case 'fetching': {
      return {fetching: true, data: null, error: null}
    }
    case 'fetched': {
      return {fetching: false, data, error: null}
    }
    case 'error': {
      return {fetching: false, data: null, error}
    }
    default:
      throw new Error(`Unsupported type: ${type}`)
  }
}
