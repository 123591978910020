import React from 'react'
import {navigate} from 'gatsby-link'
import {useFetch} from '../../helpers/hooks/useFetch'

function CountupTextarea({
  maxLength,
  defaultValue = '',
  onChange = () => {},
  wrapperClassName,
  ...rest
}) {
  const [length, setLength] = React.useState(defaultValue.length)
  function handleChange(e) {
    setLength(e.target.value.length)
    onChange(e)
  }
  // this allows us to increase the opacity exponensially as they near the maxLength
  const level = length ** 6 / maxLength ** 6
  return (
    <div>
      <StoredFormControl>
        <textarea
          maxLength={maxLength}
          defaultValue={defaultValue}
          onChange={handleChange}
          {...rest}
        />
      </StoredFormControl>
      <div
        style={{
          opacity: level,
          fontSize: 12,
          color: level > 0.3 ? 'red' : null,
          fontWeight: level > 0.5 ? 'bold' : null,
        }}
      >
        <span>
          {length} / {maxLength}
        </span>
        <span>{length >= maxLength ? ' please be more brief' : null}</span>
      </div>
    </div>
  )
}

const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args))

function StoredFormControl({
  children,
  formControl = React.Children.only(children),
  lsKey = `lsfc:${formControl.props.name}`,
  queryParamName = lsKey.replace(/lsfc:/, ''),
  defaultValue = formControl.props.defaultValue,
}) {
  const [hasChanged, setHasChanged] = React.useState(false)
  const [value, setValue] = React.useState(() => {
    if (typeof window === 'undefined') {
      return defaultValue
    }
    const searchParams = new URL(window.location).searchParams
    const queryParamValue = searchParams.get(queryParamName)
    return queryParamValue || window.localStorage.getItem(lsKey) || defaultValue
  })

  if (
    formControl.props.value !== undefined &&
    formControl.props.value !== value
  ) {
    setValue(formControl.props.value)
  }

  React.useEffect(() => {
    if (hasChanged) {
      if (value) {
        window.localStorage.setItem(lsKey, value)
      } else {
        window.localStorage.removeItem(lsKey)
      }
    }
  }, [value, lsKey, hasChanged])

  return React.cloneElement(formControl, {
    onChange: callAll(formControl.props.onChange, e => {
      setHasChanged(true)
      setValue(e.target.value)
    }),
    value,
    defaultValue: undefined,
  })
}

function ContactForm() {
  const [body, setBody] = React.useState('')

  const {fetching, data, error} = useFetch({
    url: `${process.env.NETLIFY_FUNCTIONS_URL}/contact`,
    body,
  })

  React.useEffect(() => {
    if (fetching) {
      return
    }
    if (data) {
      navigate('/contact/success')
    }
    if (error) {
      /* eslint no-console:0 */
      console.log(error)
      setBody('')
    }
  }, [fetching, data, error])

  function handleSubmit(e) {
    e.preventDefault()
    setBody(JSON.stringify(getFormValues(e.target)))
  }

  return (
    <form
      name="contact"
      onSubmit={handleSubmit}
      css={{
        display: 'grid',
        gridGap: 20,
      }}
    >
      <div>
        <label htmlFor="name-input">Name</label>
        <br />
        <StoredFormControl>
          <input id="name-input" type="text" name="name" required />
        </StoredFormControl>
      </div>
      <div>
        <label htmlFor="email-input">Email</label>
        <br />
        <StoredFormControl>
          <input id="email-input" type="email" name="email" required />
        </StoredFormControl>
      </div>
      <div css={{display: 'grid', gridGap: 20}}>
        <div>
          <label htmlFor="subject-input">Email Subject</label>
          <br />
          <StoredFormControl lsKey="lsfc:training-subject">
            <input
              defaultValue=""
              type="text"
              id="subject-input"
              name="subject"
              required
              css={{width: '100%'}}
            />
          </StoredFormControl>
        </div>
      </div>
      <div>
        <div>
          <label htmlFor="body-textarea">Email body</label>
          <small css={{marginLeft: 6}}>(**markdown** _supported_)</small>
        </div>
        <CountupTextarea
          id="body-textarea"
          name="body"
          style={{width: '100%'}}
          rows="10"
          maxLength="1000"
          minLength="10"
          required
        />
      </div>
      <div>
        <button type="submit" disabled={fetching}>
          Send
        </button>
        {fetching ? <span css={{marginLeft: 10}}>...</span> : null}
        {error ? (
          <div role="alert" css={{color: 'red'}}>
            {error.message ? (
              <>
                <div>Something went wrong:</div>
                <pre
                  style={{
                    backgroundColor: 'inherit',
                    whiteSpace: 'normal',
                  }}
                >
                  {error.message}
                </pre>
              </>
            ) : (
              <div>
                {`Something went wrong, but I'm not quite sure what. Feel free to `}
                <a href="https://twitter.com/chegehimself">
                  ping me on twitter
                </a>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </form>
  )
}

function getFormValues(formNode) {
  return Object.getOwnPropertyNames(formNode.elements).reduce((obj, key) => {
    const formControl = formNode.elements[key]
    const name = formControl.getAttribute('name')
    if (name && !obj[name]) {
      obj[name] = formControl.value
    }
    return obj
  }, {})
}

export default ContactForm
