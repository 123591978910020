import {darken, lighten} from 'polished'
import {fonts} from '../src/lib/typography'

const brand = {
  primary: '#4AB19A', // Primary brand color (vibrant blue)
}

const colors = {
  primaryBase: brand.primary, // Main brand color, used extensively across the site
  primaryLight: `${lighten(0.2, brand.primary)}`, // Used for backgrounds that need to be slightly highlighted
  primaryDark: `${darken(0.1, brand.primary)}`, // Used for text or elements on hover for depth

  baseLight: '#E1E1E1', // Used for background elements where a light touch is needed
  baseDark: '#4A4A4A', // Used for text where less contrast is desired

  coreBlack: '#000000', // Used for maximum contrast text or elements
  coreWhite: '#FFFFFF', // Universal background color

  neutralLight: '#FAFAFA', // Used for areas where white is too stark
  neutralBackground: '#F5F5F5', // Standard background color across the UI

  textPrimary: '#333333', // Primary text color for best readability
  textSecondary: '#fff', // Primary text color for best readability

  actionNormal: brand.primary, // Normal state for clickable actions
  actionActive: `${darken(0.1, brand.primary)}`, // Active or hover state for clickable actions

  danger: '#E75248', // Used for errors or critical warnings

  accent: '#4AB19A', // Secondary accent color for less prominent UI elements
  accentSoftActive: `${darken(0.1, '#4AB19A')}`, // Hover state for secondary accent elements

  accentDeep: darken(0.3, '#4AB19A'), // Background color for headers or significant layout divisions
}

const theme = {
  colors,
  fonts,
  brand,
  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
  container: {
    base: '100rem',
    text: '55rem',
  },
  spacer: {
    horizontal: '2rem',
    vertical: '3rem',
  },
  transition: {
    ease: 'all 100ms ease',
  },
}

export default theme
